import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/assets/dsfr_plus_icons.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/apple-touch-icon.png");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.ico");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@codegouvfr/react-dsfr/mui.js");
;
import(/* webpackMode: "eager", webpackExports: ["DsfrProvider"] */ "/app/node_modules/@codegouvfr/react-dsfr/next-appdir/DsfrProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Link/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-notion-x/src/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["StartDsfr"] */ "/app/ui/app/[lang]/StartDsfr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StartIntl"] */ "/app/ui/app/i18n/StartIntl.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/ui/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/ui/components/header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/app/ui/context/AuthContext.tsx");
